import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
gsap.registerPlugin(Flip);

const archiveWrapper = document.querySelector(".archive__wrapper");
const archiveItems = gsap.utils.toArray(".archive__row > .archive__item");
const filterText = document.querySelector(".archive__filter-text");

if (archiveItems) {
	const filterButtons = [...document.querySelectorAll(".archive__button")];

	const filterItems = (category) => {
		const selectedCategoryItems = archiveItems.filter((item) => {
			return item.getAttribute("data-category") === category;
		});
		return selectedCategoryItems;
	};

	const filterCategory = (category, clickedButton) => {
		// Update the URL query with the selected category
		const url = new URL(window.location);
		url.searchParams.set('category', category);
		window.history.pushState({}, '', url);

		filterButtons.forEach((button) => {
			button.classList.remove("active");
		});
		clickedButton.classList.add("active");

		const state = Flip.getState(archiveItems);
		const filteredItems = filterItems(category);

		if (!filteredItems || filteredItems.length === 0) {
			archiveWrapper.style.display = "none";
			filterText.style.display = "block";
			filterText.textContent = "Fant ingenting på ditt søk";
		} else {
			archiveWrapper.style.display = "block";
			filterText.style.display = "none";
			filterText.textContent = "";
		}

		gsap.set(archiveItems, {
			display: "none",
		});

		gsap.set(filteredItems, {
			display: "inline-block",
		});

		Flip.from(state, {
			duration: 0,
			onEnter: (elements) => gsap.fromTo(elements, { opacity: 0, y: 8 }, { opacity: 1, duration: 0, y: 0, stagger: 0.03 }),
			onLeave: (elements) => gsap.to(elements, { opacity: 0, y: 8, stagger: 0, duration: 0.6 }),
		});
	};

	filterButtons.forEach((button) => {
		const category = button.getAttribute("data-category");

		button.addEventListener("click", () => {
			filterCategory(category, button);
		});
	});

	const urlParams = new URLSearchParams(window.location.search);
	const defaultCategory = urlParams.get('category') || "exhibition";
	const defaultButton = filterButtons.find((button) => button.getAttribute("data-category") === defaultCategory);

	if (defaultButton) {
		defaultButton.click();
	}
}
