const modal = document.getElementById("productModal");
const openModalBtn = document.getElementById("openButton");
const closeModalBtn = document.getElementById("closeButton");

if (modal) {
    openModalBtn.onclick = function() {
      modal.style.display = "flex";
    }

    closeModalBtn.onclick = function() {
      modal.style.display = "none";
    }

    window.onclick = function(event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    }
}